.whatsnew {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* align-items: center; */
}

.whatsnew .item {
  margin: 8px;
  max-width: 540px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.whatsnew a {
  text-decoration: none;
}

.whatsnew a:hover {
  background: #a7262627;
  font-size: 1.04em;
}

.whatsnew .item .days {
  font-weight: 800;
  margin: 0 8px 0 16px;
  color: #a72626;
  font-size: 1.25em;
}

.whatsnew .item .text {
  margin: 0 16px 0 8px;
  max-width: 480px;
  text-align: start;
  color: black;
  font-size: 1.25em;
}
